import api from '../../api/api';
import * as ACTIONS from '../actionTypes';
import {
  UPDATE_CHANNEL_KEY,
  UPDATE_API_KEY,
  UPDATE_USER_INFO,
  CLEAR_USER_INFO,
  UPDATE_GEO_DATA,
  UPDATE_USER_FEATURES,
} from '../mutationTypes';
import {CHANNEL_KEY, URL_QUERIES} from '@/const';
import Vue from 'vue';

export default {
  [ACTIONS.GET_CHANNEL_KEY]: async ({commit}) => {
    const key = CHANNEL_KEY && {key: CHANNEL_KEY} || await api.authApi.get_channel_key();
    commit(UPDATE_CHANNEL_KEY, key);
  },
  [ACTIONS.GET_API_KEY]: async ({commit}) => {
    commit(UPDATE_API_KEY, await api.authApi.get_api_key());
  },
  [ACTIONS.GET_PROFILE]: async ({commit}) => {
    const res = await api.authApi.profile();
    commit(UPDATE_USER_INFO, res);
    commit(UPDATE_GEO_DATA, res?.data);
  },
  [ACTIONS.GET_USER_ACCESS]: async ({commit}) => {
    const res = await api.authApi.getUserAccess();
    commit(UPDATE_USER_FEATURES, res.data);
  },
  [ACTIONS.DO_LOGIN]: async ({commit}, params) => {
    const res = await api.authApi.login(params);
    commit(UPDATE_USER_INFO, res);
    commit(UPDATE_GEO_DATA, res?.data);
  },
  [ACTIONS.DO_REGISTRATION]: async ({commit}, params) => {
    const res = await api.authApi.registration(params);
    commit(UPDATE_USER_INFO, res);
    commit(UPDATE_GEO_DATA, res?.data);
  },
  [ACTIONS.DO_LOGOUT]: async ({commit}, params) => {
    Vue.$storage.remove(URL_QUERIES.V3_OUTER_TRACKING);
    commit(CLEAR_USER_INFO, await api.authApi.logout(params));
  },
  [ACTIONS.DO_VERIFY]: async (state, params) => {
    return await api.authApi.verify(params);
  },
  [ACTIONS.DO_PASSWORD_RESET_REQUEST]: async (state, params) => {
    return await api.authApi.passwordResetRequest(params);
  },
  [ACTIONS.DO_PASSWORD_RESET]: async (state, params) => {
    return await api.authApi.passwordReset(params);
  },
  [ACTIONS.DO_UPDATE_PROFILE]: async ({commit}, params) => {
    const res = await api.authApi.updateProfile(params);
    commit(UPDATE_USER_INFO, res);
    commit(UPDATE_GEO_DATA, res?.data);
  },
  [ACTIONS.ADD_SESSION_BY_CODE]: async (_, params) => {
    return await api.authApi.addSessionByCode(params);
  },
  [ACTIONS.SOCIAL_AUTH]: async ({
    commit,
    dispatch,
  }, params) => {
    const res = await api.authApi.socialAuth(params);
    commit(UPDATE_USER_INFO, res);
    commit(UPDATE_GEO_DATA, res?.data);
  },
  [ACTIONS.CONFIRM_ACCOUNT_DELETE]: async (_, params) => {
    return await api.authApi.confirmAccountDelete(params);
  },
};
