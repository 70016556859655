// ----------- AUTH
export const UPDATE_CHANNEL_KEY = 'UPDATE_CHANNEL_KEY';
export const UPDATE_API_KEY = 'UPDATE_API_KEY';
export const UPDATE_USER_INFO = 'UPDATE_USER_INFO';
export const CLEAR_USER_INFO = 'CLEAR_USER_INFO';
export const UPDATE_USER_FEATURES = 'UPDATE_USER_FEATURES';

// ----------- CALENDAR
export const UPDATE_LIST_PARAMS = 'UPDATE_LIST_PARAMS';
export const UPDATE_CALENDAR_PARAMS = 'UPDATE_CALENDAR_PARAMS';
export const RESET_EVENT_PARAMS = 'RESET_EVENT_PARAMS';

// ----------- CHANNEL
export const UPDATE_CHANNEL_SETTINGS = 'UPDATE_CHANNEL_SETTINGS';
export const UPDATE_CHANNEL_MENU = 'UPDATE_CHANNEL_MENU';
export const UPDATE_GROUP_MENU = 'UPDATE_GROUP_MENU';
export const UPDATE_MAIN_CHANNEL_SLIDES = 'UPDATE_MAIN_CHANNEL_SLIDES';
export const UPDATE_CHANNEL_PAGES = 'UPDATE_CHANNEL_PAGES';
export const UPDATE_CHANNEL_COLORS = 'UPDATE_CHANNEL_COLORS';

// ----------- CUSTOM FORM
export const UPDATE_CUSTOM_FORM_DATA = 'UPDATE_CUSTOM_FORM_DATA';

// ----------- EVENT
export const UPDATE_EVENTS_CATEGORIES = 'UPDATE_EVENTS_CATEGORIES';

// ----------- FAQ
export const UPDATE_FAQ = 'UPDATE_FAQ';

// ----------- GEO
export const UPDATE_GEO_DATA = 'UPDATE_GEO_DATA';

// ----------- PARTICIPANTS
export const UPDATE_PARTICIPANTS_LIST = 'UPDATE_PARTICIPANTS_LIST';
export const UPDATE_PARTICIPANT_GROUP_LIST = 'UPDATE_PARTICIPANT_GROUP_LIST';
export const UPDATE_PARTICIPANTS_PROPS = 'UPDATE_PARTICIPANTS_PROPS';

// ----------- PAYMENT
export const UPDATE_PACKAGES_LIST = 'UPDATE_PACKAGES_LIST';
export const UPDATE_SUBSCRIPTIONS_LIST = 'UPDATE_SUBSCRIPTIONS_LIST';
export const UPDATE_ORDERS_LIST = 'UPDATE_ORDERS_LIST';

// ----------- SETTINGS
export const ADD_TOAST = 'ADD_TOAST';
export const REMOVE_TOAST = 'REMOVE_TOAST';
export const SET_POPUP_DATA = 'SET_POPUP_DATA';
export const DELETE_POPUP = 'DELETE_POPUP';

// ----------- CLIPS
export const UPDATE_CLIPS = 'UPDATE_CLIPS';
export const UPDATE_ALL_CLIPS = 'UPDATE_ALL_CLIPS';

// ----------- FAVORITES
export const UPDATE_FAVORITES = 'UPDATE_FAVORITES';

// ------------ GLOBAL
export const CLEAR_CACHE = 'CLEAR_CACHE';
export const RESET_STATE = 'RESET_STATE';
